<template>
    <div>

    </div>
</template>

<script>
    import { mapState } from "vuex"
  export default {
    name: "typeRealEstateFilter",
    props:['item'],
    computed:{
      ...mapState(['landing']),
      ...mapState('realEstate',['realEstates']),
    },
    data(){
      return{
        enableFilter:false,
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
