<template>
    <div>
        <div style="display: flex;justify-content: flex-end;margin-bottom: 15px">

            <v-btn color="primary" v-if="localPage !== 'list'" outlined  @click="changeLocalPageOneBack" small>
                <v-icon>mdi-chevron-left</v-icon>
                <span>Back</span>
            </v-btn>
            <v-btn color="primary" v-if="localPage === 'list'" outlined  @click="createRealEstate" small>
                <v-icon>mdi-plus</v-icon>
                <span>Create</span>
            </v-btn>
            <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn style="margin-left: 15px;" color="primary" dark outlined small v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-if="localPage !== 'list'">
                        <v-list-item-title
                                style="cursor: pointer" @click="changeLocalPage('list')"
                        >Table listing</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item v-if="localPage !== 'sort'">
                        <v-list-item-title
                                style="cursor: pointer" @click="changeLocalPage('sort')"
                        >Sort Settings</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item v-if="localPage !== 'filter'">
                        <v-list-item-title
                                style="cursor: pointer" @click="changeLocalPage('filter')"
                        >Filter Settings</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item v-if="localPage !== 'map'">
                        <v-list-item-title
                                style="cursor: pointer" @click="changeLocalPage('map')"
                        >Map Settings</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item v-if="localPage !== 'style'">
                        <v-list-item-title
                                style="cursor: pointer" @click="changeLocalPage('style')"
                        >Style Settings</v-list-item-title
                        >
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <v-data-table v-if="localPage === 'list'"
                class="table"
                :headers="headers"
                :items="realEstates"
                hide-default-footer
        >
            <template v-slot:body="{ items }">
                <tbody v-if="items.length > 0">

                <tr v-for="item in items" :key="item.id">
                    <td class="d-block d-sm-table-cell" style="text-align: center"><img style="width:60px;height:40px;" :src="item.main_image?item.main_image:require('@/assets/none.png')" :alt="item.name[landing.current_lang]"></td>
                    <td class="d-block d-sm-table-cell"
                        style="text-align: center; cursor: pointer"
                    >
                        {{ item.name[landing.current_lang] }}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                        <v-chip
                                class="ma-2"
                                :color="item.active ? 'green' : 'red'"
                                text-color="white"
                                style="cursor: pointer"
                                @click="changeActive(item)"
                        >
                            {{ item.active ? "Active" : "Inactive" }}
                        </v-chip>
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                        <v-menu offset-y open-on-hover bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>
                                    <v-icon>mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title
                                            style="cursor: pointer"
                                            @click="openDetail(item)"
                                    >
                                        Edit
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-title
                                            style="cursor: pointer; color: red" @click="deleteItemOpen(item)"
                                    >Delete</v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td class="d-block d-sm-table-cell"
                        colspan="3"
                        style="text-align: center; padding: 0 15px; font-weight: normal"
                    >
                        You don't have items yet, it's time to create them
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>

        <div v-if="localPage === 'filter'">
            <type-real-estate-filter :item="item" />
        </div>
        <PopupSuccess ref="PopupSuccess" title="Congratulations!" :content="successMessage" />
        <PopupDelete ref="PopupDelete"  @confirm="deleteItemConfirm" :title="`Are you sure about delete ${deleteItem?deleteItem.name[landing.current_lang]:'default name'}?`"/>
    </div>
</template>

<script>
    import {mapState,mapMutations,mapActions} from "vuex";
    import TypeRealEstateFilter from "./typeRealEstateFilter";
  export default {
    name: "typeRealEstateList",
    props:['item'],
    components:{
      TypeRealEstateFilter,
      PopupSuccess:() => import("@/components/Popup/PopupSuccess"),
      PopupDelete:() => import("@/components/Popup/PopupDelete"),
    },
    data(){
      return{
        deleteItem:null,
        localPagesList:["list"],
        headers:[
          { text: "Image", value: "image", align: "center" },
          { text: "Name", value: "name", align: "center", sortable: true },
          { text: "Options", align: "center", sortable: true },
        ],
        successMessage:"",
        defaultRealEstate:{
          hotel_id:null,
          menu_id:null,
          video_link:"",
          price:0,
          sale_price:0,
          max_guest:0,
          min_day_stays:0,
          type_of_estate:"",
          id:999999,
          name:{
            en:"default name"
          },
          description:{
            en:"default description"
          },
          images:[],
          city:"",
          facilities:[],
          booking_links:[],
          messengers:[],
          stars_count:0,
          rooms_count:0,
          beds_count:0,
          wc_count:0,
          room_size:0,
          from_center:0,
          from_sea:0,
          zoom:9,
          pool:0,
          restaurant:0,
          pub:0,
          active:1,
          main_image:"",
          email:"",
          phone:"",
          coordinates:{
            lat:0,
            lng:0
          },
          address:"",
          working_time:""
        },
        localPage:"list"
      }
    },
    computed:{
      ...mapState('realEstate',['realEstates']),
      ...mapState(['landing']),
    },
    methods:{
      ...mapMutations('realEstate',['setRealEstate']),
      ...mapActions('realEstate',['putRealEstate','deleteRealEstate']),
      changeLocalPage(page){
        this.localPage = page;
        this.localPagesList.push(page);
        },
      changeLocalPageOneBack(){
        if(this.localPagesList.length){
          this.localPage = this.localPagesList[this.localPagesList.length===1?0:this.localPagesList.length-2];
          this.localPagesList.push('list');
        }
      },
      async deleteItemConfirm(){
        await this.deleteRealEstate(this.deleteItem.id);
      },
      async deleteItemOpen(item){
        this.deleteItem = item;
        this.$refs.PopupDelete.open();
      },
      async changeActive(item){
        if(item.active){
          item.active = 0;
        }else{
          item.active = 1;
        }
      },
      openDetail(item){
        this.setRealEstate(item);
        this.$emit('setType','edit');
        this.$emit('changePage','detail');
      },
      createRealEstate(){
        const realEstate = JSON.parse(JSON.stringify(this.defaultRealEstate));
        realEstate.hotel_id = +this.$route.params.id;
        realEstate.menu_id = +this.item.id;
        realEstate.default_val = true;
        this.setRealEstate(realEstate);
        this.realEstates.push(realEstate);
        console.log(realEstate);
        this.$emit('changePage','detail');
        this.$emit('setType','create');
      }
    }
  }
</script>

<style scoped>

</style>
